<template>
  <header id="header">
    <nav class="nav">
      <ul class="nav_bar">
        <li>
          <a href="" class="logo">
            <img class="log_img" src="@/assets/imgs/log.png" alt="" />
          </a>
        </li>
        <li><i class="nav_line"></i></li>
        <li class="nav_text">
          证券代码<br />
          814418
        </li>
        <div class="move">
          <!-- nth-child(2) -->
          <li
            class="nav_totb"
            v-for="(item, index) in header"
            :key="index"
            @click="gotouter(item.path)"
            :style="{
              fontWeight: $route.path.indexOf(item.path) === -1 ? '' : '500',
              fontSize: $route.path.indexOf(item.path) === -1 ? '' : '19px',
            }"
          >
            {{ item.titl }}
            <i class="after"></i>
          </li>
        </div>
        <li class="nav_tobs"><a class="tryout" href="#/login">登录/注册</a></li>
      </ul>
    </nav>
    <div class="position">
      <div v-for="(item, index) in header" :key="index">
        <div
          class="item"
          v-for="(ite, index) in item.list"
          :key="index"
          @click="gotouter(ite.path, ite.inedx)"
          :style="{
            fontSize: $route.path.indexOf(ite.path) === -1 ? '' : '17px',
            fontWeight: $route.path.indexOf(ite.path) === -1 ? '' : '300',
            color: $route.path.indexOf(ite.path) === -1 ? '' : '#ffffff',
          }"
        >
          {{ ite.title }}
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import VueCookies from "vue-cookies"; // 设置cookies

export default {
  data() {
    return {
      token: VueCookies.get("tokenid"),
      //外部链接
      indexro: "https://www.kulune.net/login.html", //小程序sass
      tration: "https://www.kulune.cn/login.html", //微信小商店
      //导航循环渲染 子路由路径path
      header: [
        {
          titl: "解决方案",
          path: "/home",
          list: [
            {
              title: "品牌孵化",
              path: "/home/brand",
            },
            {
              title: "网红孵化",
              path: "/home/netred",
            },
            {
              title: "电商孵化",
              path: "/home/commerce",
            },
            {
              title: "小程序saas",
              pat: "",
              inedx: true,
            },
            {
              title: "微信小商店",
              pat: "",
              inedx: false,
            },
            {
              title: "增值服务",
              path: "/home/added",
            },
          ],
        },
        {
          titl: "经典案例",
          path: "/case",
        },
        {
          titl: " 渠道合作",
          path: "/cooperation",
        },
        {
          titl: "昆仑商学院",
          path: "/college",
        },
        {
          titl: "仟禾计划",
          path: "/qianhe",
        },
        {
          titl: "关于昆仑虚",
          path: "/aboutus",
        },
      ],
    };
  },
  methods: {
    //路由点击事件
    gotouter(path, inedx) {
      if (!path) {
        if (inedx === true) {
          window.open(this.indexro);
        } else {
          window.open(this.tration);
        }
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
#header {
  width: 100%;
  height: 100px;
  position: absolute;

  top: 0px;
  left: 0;
  z-index: 2000;
  .nav {
    max-width: 1290px;
    height: 44px;
    margin: 0 auto;
    margin-top: 40px;
    color: #fff;

    .nav_bar {
      > li {
        float: left;
        a > img {
          width: 100%;
        }
        .nav_line {
          width: 1px;
          height: 24px;
          background: #f2f3ff;
          display: inline-block;
          margin: 14px 15px 0 8px;
        }
      }
      .nav_text {
        margin-top: 6px;
        line-height: 19px !important;
        text-align: center;
        font-size: 15px;
        letter-spacing: 2px;
        margin-right: 49px;
      }
      .move {
        .nav_totb {
          float: left;
          font-size: 18px;
          color: #fff;
          font-weight: 300;
          letter-spacing: 2px;
          padding: 22px 44px 0px 0px;
          cursor: pointer;
        }
        .nav_totb:nth-child(6) {
          padding: 22px 0px 0px 0px;
        }

        .after {
          content: "";
          display: inline-block;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 7px solid #ffffff;
          border-radius: 5px;
          transition: 0.5s;
        }
      }
      .nav_totb:hover .after {
        transform: rotateZ(90deg);
      }
      .nav_tobs {
        float: right !important;
        line-height: 49px;
        .manag {
          border: 1px solid #ffffff;
          border-radius: 6px;
          padding: 6px;
          font-size: 15px;
          color: #ffffff;
          outline: none;
        }
        .tryout {
          background: #ffffff;
          border-radius: 6px;
          font-size: 15px;
          padding: 6px;
          color: #0033d7;
          font-weight: 600;
        }
      }
    }
  }
  .position {
    width: 100%;
    height: 0px;
    position: relative;
    left: 0px;
    top: 9px;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    .item {
      width: 90px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 300;
      padding: 11px 0;
      color: #b5babd;
      position: relative;
      left: -280px;
      top: 0px;
      cursor: pointer;
    }
    .item:nth-child(1) {
      padding-top: 22px;
    }
    .item:hover {
      color: #ffffff;
      font-size: 17px;
      font-weight: 300px;
    }
  }
}
#header:hover .position {
  height: 240px;
  transition: 1s;
}
#header:hover {
  background-color: #3b4144;
  transition: 1s;
}

// @media screen and (max-width: 765px) {
//   #header {
//     padding: 10px 117px;
//     height: 50px;
//     overflow: hidden;
//   }

//   #header a.logo {
//   }

//   #header ul.nav_bar {
//     /* border-top: solid 1px #111;
//             width: 100%; */
//   }

//   #header ul.nav_bar li {
//     padding: 0 10px;
//   }
// }
//
</style>
