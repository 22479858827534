<template>
  <!-- 脚部 -->
  <footer class="foot">
    <div class="foot_liem">
      <div>
        <ul class="foot_ltie"  v-for="(item, index) in ction"
            :key="index">
          <li>{{item.ption}}</li>
          <li  v-for="(itlm, index) in item.content"
            :key="index"><a href="#">{{itlm.piece}}</a></li>
        </ul>
       
        <ul class="foot_ltie">
          <li class="child">咨询热线</li>
          <li class="child">028 65199065</li>
          <li class="child">
            (周一至周五 9:00-22:00<br />周六至周日 9:00-18:00)
          </li>
        </ul>
      </div>
      <div>
        <ul class="foot_ltor">
          <li><img src="@/assets/imgs/H5.png" alt="" /></li>
          <li>昆仑虚H5</li>
        </ul>
        <ul class="foot_ltor">
          <li><img src="@/assets/imgs/klxgzh.jpg" alt="" /></li>
          <li>昆仑虚官方公众号</li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <p>
        Copyright©2013-2021四川昆仑虚科技发展有限公司
        <a href="#">www.kulune.comAll Rights Reserved. </a>
        <a class="a" href="#">法律顾问：四川法奥律师事务所</a>
      </p>

      <p>
        增值电信业务营业许可证：B1-20140209
        <a class="aj" href="#">工信部备案：蜀ICP备2021000314号-3</a>
        公司地址：成都市成华区猛追湾街道166号339 Office B座523-529
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      // 列表循环渲染
      ction: [
        {
          ption: "产品服务",
          content: [
            {
              piece: "网红孵化",
            },
            {
              piece: "电商孵化",
            },
            {
              piece: "品牌孵化",
            },
            {
              piece: "小程序saas",
            },
            {
              piece: "腾讯广告",
            },
          ],
        },
        {
          ption: "商务合作",
          content: [
            {
              piece: "申请代理",
            },
            {
              piece: "主播驻场",
            },
            {
              piece: "活动代言",
            },
            {
              piece: "直播带货",
            },
          ],
        },
        {
          ption: "帮助支持",
          content: [
            {
              piece: "新手入门",
            },
            {
              piece: "使用指南",
            },
            {
              piece: "常见问题",
            },
          ],
        },
        {
          ption: "关于我们",
          content: [
            {
              piece: "公司简介",
            },
            {
              piece: "企业文化",
            },
            {
              piece: "荣誉奖项",
            },
            {
              piece: "招纳贤士",
            },
          ],
        },
      ],
    };
  },
};
</script>

 <style scoped lang="less">
.foot {
  width: 100%;
  background-color: #1c1d1f;
  overflow: hidden;
  .foot_liem {
    max-width: 1290px;
    min-width: 1205px;
    margin: 0 auto;
    height: 200px;
    padding: 60px 60px 25px 60px;
    border-bottom: 1px solid #e9e9e9;

    .foot_ltie {
      float: left;
      li:nth-child(1) {
        font-size: 17px;
        font-weight: bold;
        color: #eaeaea;
        margin-bottom: 10px;
      }
      li {
        font-size: 15px;
        color: #a2a3a4;
        line-height: 30px;
        padding-right: 90px;
        > a {
          font-size: 15px;
          color: #a2a3a4;
          line-height: 30px;
        }
        a:hover {
          color: #fff;
        }
      }
      .child {
        padding-right: 0px;
      }
    }

    .foot_ltor {
      float: right;
      margin-left: 60px;
      text-align: center;
      li > img:nth-child(1) {
        width: 114px;
        height: 114px;
      }
      li:nth-child(2) {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 300;
        color: #ebebeb;
        line-height: 36px;
      }
    }
  }
  .bottom {
    text-align: center;
    max-width: 1290px;
    margin: 0 auto;

    padding: 20px 0px;
    line-height: 30px;
    p,
    a {
      display: inline-block;
      font-size: 12px;
      font-weight: 300;
      color: #888888;
    }
    a:hover {
      color: #fff;
    }
    p:nth-child(1) > .a {
      margin-left: 20px;
    }
    p:nth-child(2) > .aj {
      margin-right: 30px;
    }
  }
}
</style>
